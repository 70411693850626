/* You can add global styles to this file, and also import other style files */

@import "variables";

/* Import the roboto style */
$roboto-font-path: '../common/assets/fonts/Roboto';
@import "fonts/roboto.scss";

/* Apply Nunito style to all fonts */
$font-family-base: 'Roboto Thin';

/* Comment this line if we don't want to use bootstrap */
@import '../../node_modules/bootstrap/scss/bootstrap';

/* Font awesome */
// $fa-font-path : '../../node_modules/font-awesome/fonts';
// @import '../../node_modules/font-awesome/scss/font-awesome';

/* Bootstrap social */
@import '../../node_modules/bootstrap-social/bootstrap-social.css';



html {
}

body {
  // background-color: #e8e8e8;
}

.bg-darker-bayer {
    background-color: $blue_bayer;
}

.bg-blue-bayer {
  background-color: $blue_bayer;
}

.bg-white {
  background-color: white;
}

.bg-score {
  background-color: $blue_bayer !important;
  color: white !important;
  font-family: 'Roboto Light' !important;
  font-size: 1.2em !important;
}

.fg-white { 
  color: white;
}

.card {
  border-radius: 0px;
  border: 1px solid $blue_bayer !important;
}

.card-header {
  background-color: $blue_bayer;
  color: white;
  border-radius: 0px !important;
  
}

.score-title {
  color: white;
  font-size: 1.2em;

  &:hover {
    color: $dark_blue_bayer;
  }

  &:active {
    color: rgb(218, 218, 218);
  }
}

.dropdown { 
  margin-left: 0 !important;
}