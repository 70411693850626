// Import Roboto font
// Source: https://fonts.google.com/specimen/Roboto?selection.family=Roboto:300,400
@font-face {
  font-family: 'Roboto Light';
  src: url('#{$roboto-font-path}/Roboto-Light.ttf'); //, url('./fonts/Nunito/Nunito-Regular.woff') format('woff');
  //TODO: check for woff version
}

@font-face {
  font-family: 'Roboto Thin';
  src: url('#{$roboto-font-path}/Roboto-Thin.ttf'); //, url('./fonts/Nunito/Nunito-Regular.woff') format('woff');
  //TODO: check for woff version
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('#{$roboto-font-path}/Roboto-Regular.ttf'); //, url('./fonts/Nunito/Nunito-Regular.woff') format('woff');
  //TODO: check for woff version
}